import type { ReactNode, FunctionComponent } from 'react'
import { createContext, useContext, useMemo, useState } from 'react'
import type { IconName } from '../Icon/Icon'
import type { IlustrationName } from '../Ilustration/Ilustration'

interface ModalProps {
  title: string
  subtitle?: string
  body: ReactNode
  primaryAction: string
  primaryIcon?: IconName
  type: 'simple' | 'graphic' | 'interactive' | 'complex'
  secondaryAction?: string
  onSecondaryClick?: () => void
  onClose?: () => void
  onPrimaryClick?: () => void
  primaryActionType: 'primary' | 'danger' | 'premium'
  titleIcon?: IconName
  ilustration?: IlustrationName
  textAlign: 'center' | 'left'
  titleAlign?: 'center' | 'left'
}

interface ModalContextProps {
  isOpen: boolean
  openModal: (mp: ModalProps) => void
  closeModal: () => void
  modalProps: ModalProps
}

export const ModalContext = createContext<ModalContextProps | undefined>(
  undefined,
)

interface ModalProviderProps {
  children: ReactNode
}

export const ModalProvider: FunctionComponent<ModalProviderProps> = ({
  children,
}) => {
  const emptyModalProps: ModalProps = {
    title: '',
    body: null,
    primaryAction: '',
    type: 'simple',
    primaryActionType: 'primary',
    textAlign: 'center',
    titleAlign: 'center',
  }

  const [isOpen, setIsOpen] = useState(false)
  const [modalProps, setModalProps] = useState<ModalProps>(emptyModalProps)

  const openModal = (newModalProps: ModalProps) => {
    setModalProps({ ...emptyModalProps, ...newModalProps })
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
    setModalProps(emptyModalProps)
  }

  const modalProviderValue = useMemo(
    () => ({ isOpen, openModal, closeModal, modalProps }),
    [isOpen, modalProps, closeModal, openModal],
  )

  return (
    <ModalContext.Provider value={modalProviderValue}>
      {children}
    </ModalContext.Provider>
  )
}

export function useModal() {
  return useContext(ModalContext)!
}
