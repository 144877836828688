import * as amplitude from '@amplitude/analytics-browser'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastType } from 'ui'
import {
  AuthType,
  LOCAL_STORAGE_AUTH_TYPE,
} from '../../../clients/CorrectoApiClient'
import { useCorrectoApi, useToast } from '../../../contexts'
import { trackUserAction } from '../../../utils/amplitude'

export const OauthGoogle = () => {
  const location = useLocation()
  const correctoApi = useCorrectoApi()
  const toastManager = useToast()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const hashParams = new URLSearchParams(location.hash.replace('#', '?'))
  const accessToken = hashParams.get('access_token')
  const state = hashParams.get('state')
  const source = state ? new URLSearchParams(state).get('source') : null
  const authMethod = state ? new URLSearchParams(state).get('authMethod') : null

  const showErrorToast = () => {
    toastManager.showToast(
      ToastType.Danger,
      t('Se ha producido un error en la autenticación con Google'),
    )
  }

  useEffect(() => {
    if (!accessToken) return

    const handleResponse = (response: any) => {
      if (response.ok) {
        amplitude.setUserId(response.body.user.uuid)
        localStorage.setItem(LOCAL_STORAGE_AUTH_TYPE, AuthType.Google)

        if (source === 'chrome-extension') {
          correctoApi.sendTokenToExtension(response.body.user)
          trackUserAction({
            name: `Sign ${authMethod === 'LOGIN' ? 'in' : 'up'} Google Chrome Extension`,
          })

          window.open('https://correctoai.com/onboarding', '_self')
        } else {
          trackUserAction({
            name: `Sign ${authMethod === 'LOGIN' ? 'in' : 'up'} Google`,
          })

          navigate('/projects')
        }
      } else {
        showErrorToast()
        localStorage.removeItem(LOCAL_STORAGE_AUTH_TYPE)
        navigate('/login')

        throw new Error(JSON.stringify(response))
      }
    }

    correctoApi
      .convertToken(accessToken)
      .then(handleResponse)
      .catch((error: unknown) => {
        throw error
      })
  }, [accessToken])

  return null
}
