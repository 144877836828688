import { Navigate, useLocation, useSearchParams } from 'react-router-dom'
import type { FC, PropsWithChildren } from 'react'
import { useCorrectoApi } from '../../contexts'

export const PublicRoute: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation()
  const correctoApi = useCorrectoApi()
  const [searchParams] = useSearchParams()
  const isExtension = searchParams.get('extension') === 'true'

  if (correctoApi.isAuthenticated()) {
    if (isExtension) {
      ;(async () => {
        const user = await correctoApi.getUser()
        correctoApi.sendTokenToExtension(user.body)
        window.open('https://correctoai.com/onboarding', '_self')
      })()

      return null
    }

    const { next }: { next: string } = location.state || {
      next: '/projects',
    }

    return <Navigate to={next} />
  }

  return <>{children}</>
}
