import type { FC, ReactNode } from 'react'
import Google24 from './assets/24px/google.svg?react'
import Facebook24 from './assets/24px/facebook.svg?react'
import Home24 from './assets/24px/home.svg?react'
import Search24 from './assets/24px/search.svg?react'
import Book24 from './assets/24px/book.svg?react'
import File24 from './assets/24px/file.svg?react'
import FilePlus24 from './assets/24px/filePlus.svg?react'
import FilePlus16 from './assets/16px/filePlus.svg?react'
import Trash16 from './assets/16px/trash.svg?react'
import Trash24 from './assets/24px/trash.svg?react'
import Settings24 from './assets/24px/settings.svg?react'
import Logout24 from './assets/24px/logout.svg?react'
import target16 from './assets/16px/target.png'
import target24 from './assets/24px/target.png'
import correctoAI from './assets/16px/correctoAI.png'
import FacebookBrand16 from './assets/16px/facebook.svg?react'
import TwitterX16 from './assets/16px/twitter-x.svg?react'
import Instagram16 from './assets/16px/instagram.svg?react'
import Linkedin16 from './assets/16px/linkedin.svg?react'
import Tiktok16 from './assets/16px/tiktok.svg?react'
import Youtube16 from './assets/16px/youtube.svg?react'
import Close16 from './assets/16px/close.svg?react'
import EyeOn24 from './assets/24px/eyeOn.svg?react'
import EyeOn16 from './assets/16px/eyeOn.svg?react'
import EyeOff24 from './assets/24px/eyeOff.svg?react'
import EyeOff16 from './assets/16px/eyeOff.svg?react'
import CirclePlus16 from './assets/16px/circlePlus.svg?react'
import CirclePlus24 from './assets/24px/circlePlus.svg?react'
import Pencil16 from './assets/16px/pencil.svg?react'
import Pencil24 from './assets/24px/pencil.svg?react'
import Bold16 from './assets/16px/bold.svg?react'
import Italic16 from './assets/16px/italic.svg?react'
import Underline16 from './assets/16px/underline.svg?react'
import AlertCircle24 from './assets/24px/alertCircle.svg?react'
import ChevronDown16 from './assets/16px/chevronDown.svg?react'
import Key16 from './assets/16px/key.svg?react'
import Key24 from './assets/24px/key.svg?react'
import ErrorCircle24 from './assets/24px/errorCircle.svg?react'
import CircleCheck24 from './assets/24px/circleCheck.svg?react'
import Crown24 from './assets/24px/crown.svg?react'
import Crown16 from './assets/16px/crown.svg?react'
import Bookmark16 from './assets/16px/bookmark.svg?react'
import FileCertificate24 from './assets/24px/fileCertificate.svg?react'
import Wand16 from './assets/16px/wand.svg?react'
import Bulb16 from './assets/16px/bulb.svg?react'
import BrandChrome24 from './assets/24px/brandChrome.svg?react'
import ChromeColor from './assets/16px/chromeColor.svg?react'

interface IconMap {
  sm?: ReactNode
  md?: ReactNode
}

const iconPaths: Record<string, IconMap> = {
  home: {
    md: <Home24 />,
  },
  search: {
    md: <Search24 />,
  },
  close: {
    sm: <Close16 />,
  },
  'eye-on': {
    sm: <EyeOn16 />,
    md: <EyeOn24 />,
  },
  'eye-off': {
    sm: <EyeOff16 />,
    md: <EyeOff24 />,
  },
  'alert-circle': {
    md: <AlertCircle24 />,
  },
  'error-circle': {
    md: <ErrorCircle24 />,
  },
  file: {
    md: <File24 />,
  },
  book: {
    md: <Book24 />,
  },
  settings: {
    md: <Settings24 />,
  },
  logout: {
    md: <Logout24 />,
  },
  'file-plus': {
    md: <FilePlus24 />,
    sm: <FilePlus16 />,
  },
  trash: {
    sm: <Trash16 />,
    md: <Trash24 />,
  },
  crown: {
    md: <Crown24 />,
    sm: <Crown16 />,
  },
  pencil: {
    sm: <Pencil16 />,
    md: <Pencil24 />,
  },
  'chevron-down': {
    sm: <ChevronDown16 />,
  },
  'circle-plus': {
    sm: <CirclePlus16 />,
    md: <CirclePlus24 />,
  },
  bold: {
    sm: <Bold16 />,
  },
  italic: {
    sm: <Italic16 />,
  },
  underlined: {
    sm: <Underline16 />,
  },
  bookmark: {
    sm: <Bookmark16 />,
  },
  key: {
    sm: <Key16 />,
    md: <Key24 />,
  },
  'circle-check': {
    md: <CircleCheck24 />,
  },
  'twitter-x': {
    sm: <TwitterX16 />,
  },
  instagram: {
    sm: <Instagram16 />,
  },
  linkedin: {
    sm: <Linkedin16 />,
  },
  tiktok: {
    sm: <Tiktok16 />,
  },
  youtube: {
    sm: <Youtube16 />,
  },
  'facebook-brand': {
    sm: <FacebookBrand16 />,
  },
  google: {
    md: <Google24 />,
  },
  'brand-chrome': {
    md: <BrandChrome24 />,
  },
  facebook: {
    md: <Facebook24 />,
  },
  target: {
    sm: <img alt="target" height="16px" src={target16} width="16px" />,
    md: <img alt="target" height="24px" src={target24} width="24px" />,
  },
  'file-certificate': {
    md: <FileCertificate24 />,
  },
  wand: {
    sm: <Wand16 />,
  },
  bulb: {
    sm: <Bulb16 />,
  },
  correctoAI: {
    sm: <img alt="Correcto AI" height="16px" src={correctoAI} />,
  },
  chromeColor: {
    md: <ChromeColor />,
  },
}

export const ICONS: Record<string, IconName> = Object.keys(iconPaths).reduce(
  (acc: Record<string, IconName>, curr) => {
    acc[curr.toUpperCase()] = curr
    return acc
  },
  {},
)

export type IconName = keyof typeof iconPaths

interface Icon {
  icon: IconName
  size: 'sm' | 'md'
}

export const Icon: FC<Icon> = ({ icon, size }) => {
  if (iconPaths[icon]?.[size]) {
    return <>{iconPaths[icon][size]}</>
  }
  // eslint-disable-next-line no-console -- Helps in development mode when a new icon is added
  console.error(`Icon ${icon} with size ${size} not found`)
  return null
}
