import React, {
  createContext,
  type FC,
  type PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react'
import type { ToastType } from 'ui'
import { Toast } from 'ui'
import type { IconName } from 'ui/src/Icon/Icon'
import { v4 as uuidv4 } from 'uuid'

interface ToastContext {
  showToast: (
    type: ToastType,
    content: React.ReactNode,
    timeout?: number,
    icon?: IconName,
  ) => void
  hideToast: (id: string) => void
}

export const ToastContext = createContext<ToastContext | null>(null)

interface Toast {
  id: string
  type: ToastType
  content: React.ReactNode
  timeout?: number
  icon?: IconName
}

export const ToastProvider: FC<PropsWithChildren> = ({ children }) => {
  const [toasts, setToasts] = useState<Toast[]>([])

  const showToast = useCallback(
    (
      type: ToastType,
      content: React.ReactNode,
      timeout?: number,
      icon?: IconName,
    ) => {
      setToasts(currentToasts => [
        ...currentToasts,
        { id: uuidv4(), type, content, timeout, icon },
      ])
    },
    [],
  )

  const hideToast = useCallback((id: string) => {
    setToasts(currentToasts => currentToasts.filter(toast => toast.id !== id))
  }, [])

  return (
    <ToastContext.Provider value={{ showToast, hideToast }}>
      {children}
      <div id="toast-root">
        {toasts.map(toast => (
          <Toast
            key={toast.id}
            onClose={() => {
              hideToast(toast.id)
            }}
            content={toast.content}
            timeout={toast.timeout ?? 5000}
            type={toast.type}
            icon={toast.icon}
          />
        ))}
      </div>
    </ToastContext.Provider>
  )
}

export function useToast() {
  return useContext(ToastContext)!
}
